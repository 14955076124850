<template>
  <div class="footer white-color flex">
    <img src="../../assets/images/footer.png" />
    <div class="main flex justify-between align-end">
      <div>
        
        <div>
          <span>郑州约克计算机技术有限公司</span>
          <!-- <span class="margin-lr-hg">地址：郑州市汇泽时代广场</span> -->
        </div>
      </div>
      
    </div>
    <div class="href"><a href="https://beian.miit.gov.cn" target="_blank">Copyright@2022 郑州约克计算机技术有限公司 豫ICP备2022003381号</a></div>
    
  </div>
</template>
<script>
export default {
  name: "Footab",
  data() {
    return {
      qrcode: require("@/assets/images/qrcode.png"),
    };
  },
};
</script>
<style scoped>
.footer {
  width: 100%;
  height: 400px;
  position: relative;
}
.footer > img {
  width: 100%;
  height: 400px;
  position: absolute;
}
.main {
  width: 1200px;
  position: relative;
  z-index: 9;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  letter-spacing: 2px;
}
.qrcode {
  width: 180px;
  border-radius: 8px;
}
.MilunFont-999 {
  color: #999;
}
.href{
  width: 100%;
  position: absolute;
  bottom: 20px;
  text-align: center;
}
.href a{
  color: #ffffff;
  text-decoration: none;
}
</style>