<template>
  <div
    class="top"
    :class="isShadow ? 'shadow' : ''"
    :style="{ position: position, top: offset + 'px', 'z-index': zIndex }"
  >
    <div class="main nav flex align-center justify-between">
      <div class="flex">
        <img class="logo pointer" @click="home" :src="logo" alt="" />
        <div class="flex align-center justify-end title">
          <router-link :to="{ path: '/' }" tag="span">首页</router-link>
          <router-link :to="{ path: 'dynamic' }" tag="span"
            >企业概述</router-link
          >
          <router-link :to="{ path: 'overview' }" tag="span"
            >企业动态</router-link
          >
          <router-link :to="{ path: 'news' }" tag="span">新闻资讯</router-link>
          <router-link :to="{ path: 'about' }" tag="span">关于我们</router-link>
        </div>
      </div>
      <div class="select login flex" @click="login">
        <div class="img"><img src="../../assets/images/user.png" /></div>
        登录
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavBar",
  props: {
    position: {
      type: String,
      default: "sticky",
    },
    offset: {
      type: Number,
      default: 0,
    },
    zIndex: {
      type: Number,
      default: 100,
    },
    isShadow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      logo: require("@/assets/logo.png"),
      title: ["首页", "企业概述", "企业动态", "新闻资讯", "关于我们"],
    };
  },
  methods: {
    login() {
      this.$router.push("/login");
    },
    home() {
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
.top {
  width: 100%;
  position: relative;
  background: #ffffff;
}
.shadow {
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
}
.nav {
  height: 99px;
}
.title {
  font-size: 16px;
  margin-left: 50px;
}
.title span {
  margin-right: 50px;
  cursor: pointer;
}
.title span:hover {
  color: #02b2b5;
}
.router-link-exact-active {
  color: #02b2b5;
}
.logo {
  width: 49px;
  height: 49px;
}
.login {
  font-size: 16px;
  cursor: pointer;
}
.login .img {
  width: 18px;
  height: 18px;
  margin: auto 0;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #02b2b5;
  display: flex;
  margin-right: 5px;
}
.login .img img {
  width: 16px;
  height: 16px;
  margin: auto;
}
</style>